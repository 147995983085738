import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [];

const router = createRouter({
  history: createWebHistory((window as any).VUE_APP_PUBLICPATH),
  routes,
});

export default router;
