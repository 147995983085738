import axios from "axios"

const baseUrl = (window as any).VUE_APP_SERVERURL + (window as any).VUE_APP_API_PREFIX;

// const baseUrl = "http://192.168.2.20:30900/catalog-service"

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 5000
})

instance.interceptors.response.use(
  // 無異常: 狀態碼 = 2xx，直接回傳 response
  response => response,
  // 異常: 狀態碼 >= 300，處理異常回傳
  (error) => {
    if (error && error.response.status === 401) { // 發生 401(unauthetication)錯誤, 導引至首頁
      // window.open(`logout`, "_self");
      // window.open(`${(window as any).VUE_APP_SERVERURL}/oauth2/authorization/keycloak`, '_self');
      window.open(`/signout`, '_self');
    }
  }
)

export const getUserSystem = () => {
  return instance.get("/api/v1/user/system")
}